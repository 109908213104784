import Vue from 'vue';
import Vuex from 'vuex';
import OrderStore from '@/store/modules/OrderStore';
import TranslationStore from '@/store/modules/TranslationStore';
import ReturnStore from '@/store/modules/ReturnStore';

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        OrderStore,
        TranslationStore,
        ReturnStore
    },
    strict: process.env.NODE_ENV !== 'production'
});
export default store;
