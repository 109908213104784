import { Locales } from './locales';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from './en.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import de from './de.json';

export const numberFormats = {
    de: { currency: de.currency },
    en: { currency: de.currency }
};

export const messages = {
    [Locales.EN]: en,
    [Locales.DE]: de
};

export const defaultLocale = Locales.EN;
