import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

import Home from '@/views/Home.vue';
import OrderViewer from '@/views/OrderViewer.vue';
import Thanks from '@/views/Thanks.vue';

Vue.use(Router);

const routes: Array<RouteConfig> = [
    {
        path: '/order',
        name: 'OrderViewer',
        component: OrderViewer
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/thanks',
        name: 'Thanks',
        component: Thanks
    }
];

export default new Router({
    mode: 'history',
    routes
});
