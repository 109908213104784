







































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { OrderRequest } from '@/types';
import _ from 'lodash';

const OrderStore = namespace('OrderStore');

@Component
export default class Home extends Vue {
    @OrderStore.Getter
    private isFound!: boolean;

    @OrderStore.Action
    private getOrder!: (request: OrderRequest) => void;

    @OrderStore.State('error')
    private error!: Error;

    private email = '';
    private orderNumber = '';

    get hasErrored(): boolean | null {
        if (_.isNull(this.error)) {
            return null;
        } else {
            return false;
        }
    }

    async findOrder(event: Event): Promise<void> {
        event.preventDefault();
        this.getOrder({ email: this.email, orderNumber: this.orderNumber });
    }

    getErrorMessage(e: Error): string {
        let message = 'error.unknownError';

        if (_.has(e, 'errors')) {
            const errors = _.get(e, 'errors');

            if (_.isArray(errors)) {
                message = _.first(errors).message as string;
            }
        }

        switch (message) {
            case 'Order could not be found':
                return 'error.orderCouldNotBeFound';
        }

        return message;
    }

    create(): void {
        if (this.isFound) {
            this.$router.push('/order');
        }
    }
}
