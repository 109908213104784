import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Return, ReturnDraft } from '@/types';
import ReturnService from '@/services/ReturnService';
import router from '@/router';

@Module({ namespaced: true })
class ReturnStore extends VuexModule {
    public returnObj: Return | null = null;

    @Mutation
    public setReturn(returnObj: Return): void {
        this.returnObj = returnObj;
    }

    @Action
    public async sendReturnRequest(draft: ReturnDraft): Promise<void> {
        try {
            const returnObj = await ReturnService.create(draft);
            this.context.commit('setReturn', returnObj);
            await router.push('/thanks');
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

export default ReturnStore;
