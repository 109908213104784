import { Return, ReturnDraft } from '@/types';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';

export interface CreateReturnMutation {
    createReturn: Return;
}

class ReturnService {
    async create(draft: ReturnDraft): Promise<Return> {
        const CreateReturnEvent = `
            mutation CreateReturn($input: ReturnDraft! ) {
                createReturn( input: $input ) {
                    id
                    orderId
                    orderNumber
                    selections {
                        quantity
                        returnSelection
                        returnReason
                        replaceReason
                        lineItemId
                        productId
                        sku
                        gtin
                    }
                  }
            }`;

        try {
            const response = (await API.graphql(
                graphqlOperation(CreateReturnEvent, {
                    input: draft
                })
            )) as GraphQLResult<CreateReturnMutation>;

            return response.data?.createReturn as Return;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

export default new ReturnService();
