import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { defaultLocale } from '@/i18n';

@Module({ namespaced: true })
class TranslationStore extends VuexModule {
    private language: string = defaultLocale;

    @Mutation
    public setLanguage(language: string): void {
        this.language = language;
    }
}

export default TranslationStore;
