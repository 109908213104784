








































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Order } from '@commercetools/platform-sdk';
import { Return, ReturnSelection } from '@/types';
import _ from 'lodash';

const OrderStore = namespace('OrderStore');
const TranslationStore = namespace('TranslationStore');
const ReturnStore = namespace('ReturnStore');

@Component
export default class Thanks extends Vue {
    @ReturnStore.State('returnObj')
    private returnObj!: Return;
    @OrderStore.State('order')
    private order!: Order;
    @TranslationStore.State('language')
    private language!: string;

    getName(selection: ReturnSelection): string {
        const lineItem = this.order.lineItems.find(
            l => l.productId === selection.productId
        );

        let name = JSON.parse(`${lineItem?.name}`)['de-DE'];

        if (JSON.parse(`${lineItem?.name}`)['de']) {
            name = JSON.parse(`${lineItem?.name}`)['de'];
        }

        let license = _.get(
            _.find(_.get(lineItem, 'variant.attributes'), attribute => {
                if (
                    attribute.name === 'lizenz' ||
                    attribute.name === 'ktlizenz'
                ) {
                    return attribute;
                }
            }),
            'value',
            ''
        );

        if (license.includes('{')) {
            const valueTransformedToObject = license
                .replace('{', '{"')
                .replace('}', '"}')
                .replace(/=/g, '":"')
                .replace(', ', '", "');

            license = JSON.parse(valueTransformedToObject).label;
        }

        if (license && license !== name) {
            return `${license} - ${name}`;
        }

        return name;
    }

    create(): void {
        if (!this.order) {
            this.$router.push('/');
        }
    }
}
