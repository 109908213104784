import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import Amplify from 'aws-amplify';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import router from './router';
import store from './store';
import { defaultLocale, messages, numberFormats } from '@/i18n';
import { ValidationProvider } from 'vee-validate';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);

Vue.component('ValidationProvider', ValidationProvider);

let aws_appsync_graphqlEndpoint;
let aws_appsync_apiKey;

if (process.env.NODE_ENV === 'development') {
    // dev
    aws_appsync_graphqlEndpoint =
        'https://zc5i2p2slnc45fgyltzcncf3ju.appsync-api.eu-central-1.amazonaws.com/graphql';
    aws_appsync_apiKey = 'da2-gmfsuir465cmdiiweljrnzgblu\t';
}

if (process.env.NODE_ENV === 'production') {
    // prod
    aws_appsync_graphqlEndpoint =
        'https://feh2d5uj2fcadnzajr44gd26zq.appsync-api.eu-central-1.amazonaws.com/graphql';
    aws_appsync_apiKey = 'da2-5e4ocjinlzbzrnw2ssidfkpoey';
}

Amplify.configure({
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_graphqlEndpoint,
    aws_appsync_apiKey
});

export const i18n = new VueI18n({
    messages,
    locale: navigator.language.split('-')[0],
    fallbackLocale: defaultLocale,
    numberFormats
});

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');
