



























































































































































































































































































































































































import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { AddressDraft, LineItem, Order } from '@commercetools/platform-sdk';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { Options, ReturnDraft, ReturnSelection } from '@/types';

const OrderStore = namespace('OrderStore');
const TranslationStore = namespace('TranslationStore');
const ReturnStore = namespace('ReturnStore');

@Component
export default class OrderViewer extends Vue {
    selections: ReturnSelection[] = [];
    @ReturnStore.Action
    private sendReturnRequest!: (request: ReturnDraft) => void;
    @OrderStore.State('order')
    private order!: Order;
    @TranslationStore.State('language')
    private language!: string;
    // private returnReasons: Options[] = [
    //     {
    //         value: 0,
    //         text: this.$i18n.messages[this.language].orderView.returnReasons[0]
    //     }, // 'Wähle eine Option'
    //     { value: 1, text: 'Entspricht nicht der Vorstellung' },
    //     { value: 2, text: 'Qualität ist schlecht' },
    //     // { value: 3, text: 'Artikel ist defekt' },
    //     { value: 4, text: 'Falscher Artikel geliefert' },
    //     { value: 5, text: 'Lieferung ist beschädigt' },
    //     { value: 6, text: 'Lieferung ist unvollständig' },
    //     { value: 7, text: 'Sonstiges' }
    // ];
    // private replaceReasons: Options[] = this.returnReasons;
    // private returnOptions: Options[] = [
    //     { value: 0, text: 'behalten' },
    //     { value: 1, text: 'zurückgeben' }
    //     //        { value: 2, text: 'ersetzen' }
    // ];

    getReturnReasons(): Options[] {
        return [
            {
                value: 0,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[0]'
                    )
                )
            },
            {
                value: 1,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[1]'
                    )
                )
            },
            {
                value: 2,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[2]'
                    )
                )
            },
            // { value: 3, text: 'Artikel ist defekt' },
            {
                value: 4,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[3]'
                    )
                )
            },
            {
                value: 5,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[4]'
                    )
                )
            },
            {
                value: 6,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[5]'
                    )
                )
            },
            {
                value: 7,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnReasons[6]'
                    )
                )
            }
        ];
    }

    getReturnOptions(): Options[] {
        return [
            {
                value: 0,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnOptions[0]'
                    )
                )
            },
            {
                value: 1,
                text: String(
                    _.get(
                        this.$i18n.messages[this.language],
                        'orderViewer.returnOptions[1]'
                    )
                )
            }
        ];
    }

    getImage(lineItem: LineItem): string {
        return _.get(_.first(lineItem.variant.images), 'url', '');
    }

    getLicense(lineItem: LineItem): string {
        const {
            variant: { attributes }
        } = lineItem;

        const value: any = _.get(
            _.find(attributes, attribute => {
                if (
                    attribute.name === 'lizenz' ||
                    attribute.name === 'ktlizenz'
                ) {
                    return attribute;
                }
            }),
            'value',
            ''
        );
        if (String(value).includes('{')) {
            // unfortunately, the value of "value" looks like this
            // {key=das-neinhorn, label=Das NEINhorn}
            // In order to access the attribute "label" we have to turn it into a parsable string
            // this is done via search and replace
            const valueTransformedToObject = value
                .replace('{', '{"')
                .replace('}', '"}')
                .replace(/=/g, '":"')
                .replace(', ', '", "');

            return JSON.parse(valueTransformedToObject).label;
        }
        return String(value);
    }

    getName(lineItem: LineItem): string {
        if (JSON.parse(`${lineItem.name}`)['de']) {
            return JSON.parse(`${lineItem.name}`)['de'];
        }
        return JSON.parse(`${lineItem.name}`)['de-DE'];
    }

    getSku(lineItem: LineItem): string {
        return _.get(
            _.find(lineItem.variant.attributes, ['name', 'Artikelnummer']),
            'value',
            ''
        );
    }

    getEan(lineItem: LineItem): string {
        return _.get(
            _.find(lineItem.variant.attributes, ['name', 'EAN']),
            'value',
            ''
        );
    }

    getSubtotalWithoutDiscount(): number {
        return (
            _.chain(this.order.lineItems)
                .map(lineItem => ({
                    price:
                        lineItem.quantity *
                        _.get(lineItem, 'price.value.centAmount')
                }))
                .flatten()
                .sumBy('price')
                .value() / 100
        );
    }

    getDiscounted(): number {
        return (
            this.getSubtotalWithoutDiscount() - this.getSubtotalWithDiscount()
        );
    }

    getSubtotalWithDiscount(): number {
        return this.order.totalPrice.centAmount / 100;
    }

    getTax(): number {
        return _.get(this.order, 'taxedPrice.totalTax.centAmount') / 100;
    }

    getSubtotalGross(): number {
        return (
            _.get(this.order, 'taxedPrice.totalGross.centAmount') / 100 -
            this.getShippingIncludingTax()
        );
    }

    getShippingIncludingTax(): number {
        // taxed shipping amount
        return _.get(this.order, 'shippingInfo.price.centAmount') / 100;
    }

    getTotalGross(): number {
        return _.get(this.order, 'taxedPrice.totalGross.centAmount') / 100;
    }

    getDraftedReturnItems(): ReturnSelection[] {
        const items = _.filter(this.selections, function(selection) {
            return (
                selection.returnSelection > 0 && selection.returnReason === 0
            );
        });

        if (!items) {
            return [] as ReturnSelection[];
        } else {
            return items;
        }
    }

    getToBeReturnedItems(): ReturnSelection[] {
        const items = _.filter(this.selections, function(selection) {
            return (
                selection.returnSelection > 0 &&
                selection.quantity > 0 &&
                selection.returnReason > 0
            );
        });

        if (!items) {
            return [] as ReturnSelection[];
        } else {
            return items;
        }
    }

    shouldSubmitBeDisabled(): boolean {
        const draftedReturnItems = this.getDraftedReturnItems();
        return (
            _.isEmpty(this.getToBeReturnedItems()) ||
            draftedReturnItems.length > 0
        );
    }

    submitReturnRequest(event: Event): void {
        event.preventDefault();
        this.sendReturnRequest({
            orderId: this.order.id,
            orderNumber: _.get(this.order, 'orderNumber', this.order.id),
            selections: this.getToBeReturnedItems()
        });
    }

    isReturnable(): boolean {
        const returnableUntilDate = DateTime.fromISO(
            this.order.createdAt
        ).plus({ days: 62 });
        const now = DateTime.now();
        return returnableUntilDate > now;
    }

    getOrderDate(): string {
        return DateTime.fromISO(this.order.createdAt)
            .setLocale(this.language)
            .toLocaleString();
    }

    created(): void {
        if (!this.order) {
            this.$router.push('/');
        } else {
            this.order.lineItems.forEach(lineItem => {
                this.selections.push({
                    quantity: 1,
                    returnSelection: 0,
                    replaceReason: 0,
                    returnReason: 0,
                    shippingAddress: this.order.shippingAddress as AddressDraft,
                    lineItemId: lineItem.id,
                    productId: lineItem.productId,
                    sku: this.getSku(lineItem),
                    gtin: this.getEan(lineItem)
                });
            });
        }
    }
}
