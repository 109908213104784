





















































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { defaultLocale } from './i18n';
import { LOCALES } from '@/i18n/locales';

const TranslationStore = namespace('TranslationStore');

@Component
export default class App extends Vue {
    @TranslationStore.State('language')
    private language!: string;
    private defaultLocale!: string;
    private LOCALES!: any;

    @TranslationStore.Mutation
    private setLanguage!: (lang: string) => void;

    constructor() {
        super();
        this.defaultLocale = defaultLocale;
        this.LOCALES = LOCALES;
    }

    changeLanguage(language: string): void {
        this.$i18n.locale = language;
        this.setLanguage(language);
    }
}
