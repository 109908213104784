import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import OrderService from '@/services/OrderService';
import router from '@/router';
import { OrderRequest } from '@/types';

@Module({ namespaced: true })
class OrderStore extends VuexModule {
    public order: null | OrderStore = null;
    public error: null | Error = null;

    get isFound(): boolean {
        return !!this.order;
    }

    @Mutation
    public orderFound(newOrder: OrderStore): void {
        this.order = newOrder;
        this.error = null;
    }

    @Mutation
    public orderNotFound(error: Error): void {
        this.order = null;
        this.error = error;
    }

    @Action
    public async getOrder(request: OrderRequest): Promise<void> {
        const { email, orderNumber } = request;

        try {
            const order = await OrderService.find(email, orderNumber);
            this.context.commit('orderFound', order);
            await router.push('/order');
        } catch (e) {
            console.log(e);
            this.context.commit('orderNotFound', e);
        }
    }
}

export default OrderStore;
