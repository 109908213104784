import { Order } from '@commercetools/platform-sdk';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';

export interface GetOrderByOrderNumberAndEmailQuery {
    getOrderByOrderNumberAndEmail: Order;
}

class OrderService {
    async find(email: string, orderNumber: string): Promise<Order> {
        const query = `
            query MyQuery {
              getOrderByOrderNumberAndEmail(email: "${email}", orderNumber: "${orderNumber}") {
                id
                orderNumber
                createdAt
                lineItems {
                  productId
                  quantity
                  id
                  name
                  price {
                    id
                    value {
                      centAmount
                    }
                  }
                  discountedPrice {
                    value {
                       centAmount
                    }
                  }
                  productType
                  totalPrice {
                    centAmount
                  }
                  variant {
                    attributes {
                      name
                      value
                    }
                    images {
                     url
                    }
                  }
                  taxedPrice {
                    totalGross {
                      centAmount
                    }
                    totalNet {
                      centAmount
                    }
                  }
                }
                billingAddress {
                  firstName
                  lastName
                  company
                  streetName
                  streetNumber
                  postalCode
                  city
                  country
                }
                shippingAddress {
                  firstName
                  lastName
                  company
                  streetName
                  streetNumber
                  postalCode
                  city
                  country
                }
                taxedPrice {
                  totalNet {
                    centAmount
                  }
                  totalGross {
                    centAmount
                  }
                  totalTax {
                    centAmount
                  }
                }
                shippingInfo {
                  price {
                    centAmount
                  }
                }
                totalPrice {
                  centAmount
                }
                returnInfo {
                  items {
                    lineItemId
                    quantity
                    shipmentState
                    customLineItemId
                    comment
                  }
                  returnDate
                  returnTrackingId
                }
              }
            }
        `;

        try {
            const response = (await API.graphql({ query })) as GraphQLResult<
                GetOrderByOrderNumberAndEmailQuery
            >;

            return response.data?.getOrderByOrderNumberAndEmail as Order;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

export default new OrderService();
